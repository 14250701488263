<app-notifications></app-notifications>

<div class="mobile-blur" id="menuBlur"></div>

<div id="menuBar" class="menubar" [class]="pageClass">
  <nav id="navbar" class="navbar">
    <div class="navbar__hamburger navbar__hamburger--display" id="mobileHamburger" (click)="openMobileMenu()"><ion-icon name="menu-outline"></ion-icon></div>
    <a id="logoContainer" class="navbar__logo" routerLink="/" routerLinkActive="active"><img id="logo" src="assets/images/vod_logo.svg" class="navbar__logo" alt=""/></a>
    <!-- <img src="assets/images/logo_nck.png" class="navbar__logo" alt=""/> -->

    <div class="navbar__menu" id="mobileMenu">
      <a class="navbar__link navbar__link--display" id="mobileClose" (click)="closeMobileMenu()"><ion-icon name="close-outline" class="blue"></ion-icon></a>
      <a routerLink="/category/filmyserie" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link">filmy/serie</a>
      <a routerLink="/category/programy" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link">programy</a>
      <a routerLink="/category/muzyka" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--space">muzyka</a>
      <a routerLink="/products" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--green navbar__link--display">subskrypcja</a>
      <a *ngIf="loggedIn && isParent" (click)="closeMobileMenu()" routerLink="/my-profile" routerLinkActive="active" class="navbar__link navbar__link--green navbar__link--display">moje&nbsp;konto</a>
      <a *ngIf="loggedIn" routerLink="/login/profile" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--green navbar__link--display">zmień&nbsp;profil</a>
      <a *ngIf="!loggedIn" routerLink="/login" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--green navbar__link--display">logowanie</a>
      <a *ngIf="!loggedIn" routerLink="/register" routerLinkActive="active" (click)="closeMobileMenu()" class="navbar__link navbar__link--green navbar__link--display">załóż&nbsp;konto</a>
      <!-- <div class="navbar__search orange mobile">
        <input type="text" name="search" style="width: calc(100% - 55px); margin: 10px 0;border: 1px solid #fff"  (keydown.enter)="searchQuery('searchpatternMob')" class="navbar__search-input" id="searchpatternMob" placeholder="szukaj"/>
        <button type="submit" style="display: inline-block;" class="navbar__search-btn" (click)="searchQuery('searchpatternMob')"><ion-icon style="font-size: 1.2rem;" name="search-outline"></ion-icon></button>
      </div> -->
      <a routerLink="/search" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--orange navbar__link--space navbar__link--display">szukaj</a>
      <a routerLink="/about" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--black navbar__link--display">o nas</a>
      <a routerLink="/contact" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--black navbar__link--display">kontakt</a>
      <a routerLink="/media" (click)="closeMobileMenu()" routerLinkActive="active"  class="navbar__link navbar__link--black navbar__link--display">dla prasy</a>
      <a routerLink="/teachers" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--black navbar__link--display">dla katechetów</a>
      <a routerLink="/parents" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--black navbar__link--display">dla rodziców</a>
      <a routerLink="/support" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--black navbar__link--display">wsparcie</a>
      <a routerLink="/gdpr" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--black navbar__link--display">regulamin</a>
      <a href="http://sklep.promyczek.pl/" (click)="closeMobileMenu()" target="blank" class="navbar__link navbar__link--black navbar__link--space navbar__link--display">sklep</a>
      <a *ngIf="loggedIn" routerLink="/logout" (click)="closeMobileMenu()" routerLinkActive="active" class="navbar__link navbar__link--red navbar__link--display">wyloguj</a>
    </div>

    <div id="searchbar" class="navbar__search">
        <input type="text" name="search" class="navbar__search-input navbar__search-input--display" (keydown.enter)="searchQuery('searchpattern')" id="searchpattern" placeholder="szukaj"/>
        <button type="submit" class="navbar__search-btn navbar__search-input--display" (click)="searchQuery('searchpattern')"><ion-icon name="search-outline"></ion-icon></button>
    </div>

    <div class="navbar__buttons" *ngIf="!loggedIn">
      <button routerLink="/products" routerLinkActive="active" class="desktop navbar__register navbar__register--position">subskrypcja</button>
      <button routerLink="/register" routerLinkActive="active" class="desktop navbar__register navbar__register--position">załóż&nbsp;konto</button>
      <button routerLink="/login" routerLinkActive="active" class="navbar__login-btn navbar__login-btn--display">logowanie</button>
      <button routerLink="/login" class="navbar__login-icon navbar__login-icon--display"><ion-icon name="enter-outline"></ion-icon></button>
    </div>

    <div class="navbar__buttons" *ngIf="loggedIn">
      <button routerLink="/products" routerLinkActive="active" class="desktop navbar__register navbar__register--position">subskrypcja</button>
      <div class="desktop">
        <button *ngIf="isParent" routerLink="/my-profile" routerLinkActive="active" class="navbar__register-icon navbar__register--position"><ion-icon name="settings-outline"></ion-icon></button>
        <button routerLink="/login/profile" routerLinkActive="active" class="desktop navbar__register-icon"><ion-icon name="people-outline"></ion-icon></button>
      </div>
      <button (click)="logout()" class="navbar__login-icon"><ion-icon name="exit-outline"></ion-icon></button>
    </div>
  </nav>
</div>

<div *ngIf="isSearch" class="navbar__search mobile">
  <input type="text" name="search" class="navbar__search-input navbar__search-input--display" (keydown.enter)="searchQuery('searchpattern')" id="searchpatternMobile" placeholder="szukaj"/>
  <button type="submit" class="navbar__search-btn navbar__search-input--display" (click)="searchQuery('searchpatternMobile')"><ion-icon name="search-outline"></ion-icon></button>
</div>

<router-outlet></router-outlet>

<div class="nck-footer">
  <img class="nck desktop" src="assets/images/2020-NCK_dofinans_kulturawsieci.png" />
  <img class="nck mobile" src="assets/images/2020-NCK_dofinans_kulturawsieci_mob.png" />
</div>

<footer class="footer footer--display">
  <div class="contact" >
    <div style="text-align: right;">
      <a routerLink="/about" routerLinkActive="active"  class="footer__link">O nas</a>
      <a routerLink="/contact" routerLinkActive="active" class="footer__link">Kontakt</a>
      <a href="http://sklep.promyczek.pl/" target="blank" class="footer__link">Sklep</a>
    </div>
    <div style="text-align: right;">
      <a routerLink="/parents" routerLinkActive="active"  class="footer__link">Dla rodziców</a>
      <a routerLink="/teachers" routerLinkActive="active"  class="footer__link">Dla katechetów</a>
      <a routerLink="/media" routerLinkActive="active"  class="footer__link">Dla prasy</a>
    </div>
    <div style="text-align: right;">
      <a routerLink="/support" routerLinkActive="active"  class="footer__link">Wsparcie</a>
      <a routerLink="/gdpr" routerLinkActive="active"  class="footer__link">Regulamin</a>
      <a routerLink="/faq" routerLinkActive="active"  class="footer__link">FAQ</a>
    </div>
    <img src="assets/images/vod_logo.svg" class="navbar__logo" alt=""/>
    <div>
      <a class="mail" href="mailto:vod@promyczek.pl">vod@promyczek.pl</a>
    </div>
    <div>
      Plac Kolegiacki 4<br />
      33-300 Nowy Sącz<br />
      tel. 18 443 44 00
    </div>
  </div>
</footer>